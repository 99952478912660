/* src/styles/Room.css */

/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* emote tings */
.Toastify__toast-body {
  padding: 0px !important;
  border: 3px solid white !important;
}

li::after {
  content: none !important;/* Remove any default content */
  /* Optionally, you can also reset other properties */
  background: none;
  border: none;
  /* Add any other styles you want to override */
}

/* For Firefox Only */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #666 #333;
  }
  
  /* Apply hover effect when hovering over scrollable elements */
  *:hover {
    scrollbar-color: #885e77 #333;
  }
}

/* Prevent scrolling on the body */
.room-page {
  overflow: hidden;
  background-color: black;
}

.room-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Main Content */
.main-content-room {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent scrolling */
}

.upper-half,
.lower-half {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.left-column {
  width: 15%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid white;
  overflow: hidden; /* Prevent content overflow */
}

.upper-half .left-column {
  border-bottom: 1px solid white;
}

.lower-half .left-column {
  border-top: 1px solid white;
}

.middle-column {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.upper-half .middle-column .battlefield .zone {
  background-color: rgb(22, 22, 22);
}

.right-column {
  width: 15%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid white;
  border-right: 1px solid white;
  text-align: center;
  overflow: hidden; /* Prevent content overflow */
}

.upper-half .right-column .zone {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.lower-half .right-column .zone {
  border-top: 1px solid white;
  border-right: 1px solid white;
}

/* Other Players */
.opponents-title {
  color: rgb(255, 255, 255);
  z-index: 1;
  margin-bottom: 0;
}

.other-players {
  flex: 1;
  overflow-y: hidden; /* Allow vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: #000000;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center; /* Center items horizontally */
  gap: 10px; /* Add gap between items */
  padding: 10px;
}

.player-info {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  text-align: center;
  border: 1px solid white;
  width: calc(50% - 20px); /* Take up half the width minus margins/padding */
  flex: 0 1 auto; /* Don't grow, allow shrink, auto-basis */
  height: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

/* Controls */
.controls {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  padding-top: 10px; /* Add some padding at the top */
  color: white;
  overflow: hidden; /* Prevent content overflow */
}

.control-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  width: 100% !important;
}

.control-btn {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 6px;
  font-size: 15px; /* Reduce font size */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.1s ease, color 0.1s ease;
  width: 40%;
}

.control-btn:hover {
  background-color: #885e77;
  color: white;
}

.counters,
.misc-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  flex-wrap: wrap; /* Allow buttons to wrap */
}

.counters button,
.misc-buttons button {
  margin: 3px;
  padding: 3px 5px;
  font-size: 12px; /* Reduce font size */
}

.player-name {
  font-weight: 100;
  font-size: 20px; /* Reduce font size */
}

.kick-button {
  background-color: #b82929;
  color: white;
  border: none;
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.1s ease;
  font-size: 12px; /* Reduce font size */
}

.kick-button:hover {
  background-color: #ff0000;
}

.counters-button, .player-info-button {
  background-color: #dbcbd8;
  border: none;
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.1s ease;
  font-size: 12px; /* Reduce font size */
}

.counters-button:hover, .player-info-button:hover {
  background-color: #885e77;
}

/* Battlefields */
.battlefield {
  position: relative;
  /* Existing styles retained */
  overflow: auto !important;
}

/* Style for the battlefield overlay text */
.battlefield-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; /* No background to see through */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Adjust as needed */
  color: rgba(255, 255, 255, 0.5); /* Half opacity text */
  pointer-events: none; /* Allow interactions with underlying elements */
}

.battlefield {
  cursor: not-allowed !important;
}

.battlefield,
.your-battlefield {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.battlefield-cards,
.zone-cards {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden; /* Prevent overflowing */
  height: 130px;
}

.battlefield-cards .card {
  width: 90px;
}

.library-zone {
  height: 100% !important;
}

.battlefield-card-menu {
  z-index: 9999 !important;
}

.card {
  width: 80px;
  margin: 5px;
  border: none !important;
}

.card img {
  width: 100%;
  transition: transform 0.1s;
  cursor: grab;
}

.card img:hover {
  z-index: 9999;
}

.tapped {
  transform: rotate(90deg);
  transition: transform 0.2s ease;
}

/* Zones */
.zone {
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100px;
}

.zone.graveyard {
  width: 50%;
}

.zone.exile {
  width: 50%;
}

.zone p {
  color: white;
  margin-bottom: 0;
}

.zone-card-menu {
  z-index: 20000 !important; /* Higher than zone-menu-overlay */
}

/* Overlay Background */
.zone-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensure it's above other elements */
}

/* Overlay Content */
.zone-menu-content {
  background-color: #222; /* Dark background for the content */
  padding: 20px;
  border-radius: 5px;
  max-height: 80%;
  width: 75%;
  overflow-y: auto;
  color: white;
  z-index: 10001;
}

.zone-menu-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: white;
}

.zone-menu-cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.zone-menu-cards .card {
  margin: 0; /* Remove margin here if needed */
  justify-self: center; /* Center the cards */
  width: 100%;
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
  display: none;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #333;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #885e77;
}

.battlefield .zone {
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.battlefield-zone {
  background-color: rgb(22, 22, 22);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100px;
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-right: 1px solid white;
}

.recard-image {
  transform: rotate(-90deg);
  margin-left: 10px; /* Adjust spacing as needed */
  width: 32.5%; /* Set the width to 32.5% */
}

/* .zone h4 {
  margin-bottom: 5px;
} */

.zone-row {
  display: flex;
  flex: 1;
}

.zone-row .zone {
  flex: 1;
}

.active-player-zones,
.your-zones {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.active-player-zones .zone,
.your-zones .zone {
  flex: 1 1 auto;
  min-height: 0; /* Allow flex items to shrink */
  overflow: hidden; /* Prevent content overflow */
}

.zone-cards {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* Hand Area */
.hand-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px; /* Adjusted height */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 7000;
  overflow: visible; /* Allow overflow to show hovered cards */
  pointer-events: none;
}

.hand-nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  pointer-events: auto; /* Allow interaction */
}

.cards-count {
  color: white;
  margin-bottom: 5px;
  font-size: 12px;
}

.hand-nav-button {
  background-color: rgba(0, 0, 0);
  color: white;
  border: none;
  font-size: 24px;
  width: 30px;
  height: 60px;
  cursor: pointer;
  position: relative;
  z-index: 7777;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto; /* Allow interaction */
  border-radius: 5px;
}

.hand-nav-button:hover {
  background-color: rgba(0, 0, 0);
}

.hand-nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.hand-cards {
  display: flex;
  align-items: flex-end;
}

.hand-card {
  width: 130px; /* Reduce width */
  height: 182px; /* Reduce height */
  margin: 0 3px;
  position: relative;
  transform: translateY(100px); /* Adjusted to prevent overflow */
  transition: transform 0.1s;
  pointer-events: auto;
  z-index: 7777; /* Set base z-index */
}

.hand-card:hover {
  transform: translateY(-100px) scale(1.8); /* Adjusted to prevent cutoff */
  z-index: 7778; /* Bring hovered card above other cards */
}

.hand-card img {
  width: 100%;
  height: 100%;
  cursor: grab;
}

/* Navigation Buttons */
.hand-nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 20px;
  width: 30px;
  height: 60px;
  cursor: pointer;
  position: relative;
  z-index: 7777;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hand-nav-left {
  position: relative;
  left: -5px;
}

.hand-nav-right {
  position: relative;
  right: -5px;
}

.hand-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Mulligan Screen */
.mulligan-screen {
  background-color: rgb(22, 22, 22);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
}

.mulligan-screen h2 {
  margin-bottom: 20px;
}

.mulligan-screen .hand-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 80%;
}

.mulligan-screen .hand-cards img {
  width: 180px;
  margin: 5px;
  transition: transform 0.1s;
  border-radius: 4.75% / 3.5%;
}

.mulligan-screen .hand-cards img:hover {
  transform: scale(1.5);
  z-index: 2;
}

.mulligan-screen button {
  background-color: #dbcbd8;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  margin: 0 10px;
}

.mulligan-screen button:hover {
  background-color: #885e77;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.draw-x-input {
  width: 100px;
  height: 20px;
  border-radius: 5px;
  border: none;
  background-color: white;
  color: black;
  text-align: center;
}

.scry-cards, .surveil-cards {
  align-items: center;
}

.scry-card, .surveil-card {
  width: 45%;
}

.scry-sections, .surveil-sections {
  display: flex;
  justify-content: space-between;
}

.scry-section, .surveil-section {
  flex: 1;
  margin: 10px;
}

.scry-section h3, .surveil-section h3 {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}

.scry-cards-container, .surveil-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Scry Card Actions */
.scry-card-actions, .surveil-card-actions {
  display: flex;
  justify-content: center;
  background-color: #222;
}

.scry-card-actions button, .surveil-card-actions button {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.1s ease;
}

.scry-card-actions button:hover, .surveil-card-actions button:hover {
  background-color: #885e77;
  color: white;
}

.scry-done-button, .surveil-done-button, .scry-randomized-button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  width: 25%;
  transition: background-color 0.1s ease;
}

.scry-done-button:hover, .surveil-done-button:hover {
  background-color: #885e77;
  color: white;
}

.scry-card-actions button:hover, .surveil-card-actions button:hover {
  background-color: #885e77;
  color: white;
}

/* Emote Modal Styles */
.emote-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.emote-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  color: white;
}

.emote-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.emote-img {
  width: 100%;
  height: 130px;
  cursor: pointer;
  border-radius: 5px;
  transition: filter 0.025s;
}

.emote-img:hover {
  filter: brightness(1.5);
}

/* Token Modal Styles */
.token-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.token-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  color: white;
}

.token-search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: white;
}

.token-search-results {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.token-result-card {
  width: 120px;
  cursor: pointer;
  text-align: center;
}

.token-result-card:hover {
  transform: scale(1.05);
}

.token-result-image {
  width: 100%;
  border-radius: 5px;
}

.token-modal-close {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.1s ease;
}

.token-modal-close:hover {
  background-color: #885e77;
  color: white;
}

/* Player Info Modal */
.player-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.player-info-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  color: white;
}

.player-info-modal-close {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.1s ease;
}

.player-info-modal-close:hover {
  background-color: #885e77;
  color: white;
}

.player-info-modal-content{
  background-color: #0e0e0e;
  padding: 10px;
  border-radius: 5px; 

}

.player-info-modal-item{
  text-align: left;
  background-color: #ffffff;
  color: black;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* .player-info-modal-item h4{
  text-decoration: underline;
} */

.additional-info-list {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.additional-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 5px 0;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.remove-info-btn {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
}

.additional-info-input {
  display: flex;
  gap: 10px;
}

.add-info-btn {
  padding: 5px 15px;
  background-color: #ffe1fd !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 1);
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-info-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.player-info-modal-input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #e6e6e6;
}

.counters-row {
  display: flex !important;
  justify-content: space-between;
  gap: 10px;
}

.counter-section {
  flex: 1;
  text-align: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

.counter-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.counter-btn {
  background-color: #dbcbd8;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background-color 0.1s ease;
}

.counter-btn:hover {
  background-color: #885e77;
  color: white;
}

.counter-section h4 {
  margin-bottom: 8px;
}

.counter-section h5 {
  margin: 0;
  font-size: 1.2em;
}

/* Responsive adjustments for the mulligan screen */
@media (max-width: 768px) {
  .mulligan-screen .hand-cards img {
    width: 80px;
  }
}

@media (max-width: 480px) {
  .mulligan-screen .hand-cards img {
    width: 60px;
  }
}

@media (max-width: 768px) {
  .zone-menu-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 480px) {
  .zone-menu-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  /* Adjustments for very small screens */
  .left-column,
  .right-column {
    width: 25%;
  }

  .middle-column {
    width: 50%;
  }

  .card {
    width: 50px;
    margin: 1px;
  }

  .hand-card {
    width: 50px;
    height: 70px;
    margin: 0 1px;
    transform: translateY(15px);
  }

  .hand-card:hover {
    transform: translateY(-5px) scale(1.5);
  }

  .hand-area {
    height: 60px;
  }

  .control-btn,
  .counters button,
  .misc-buttons button,
  .kick-button,
  .counters-button {
    padding: 1px 2px;
    font-size: 8px;
  }

  .player-name {
    font-size: 10px;
  }
}


/* Custom Menu Styles */
.szh-menu {
  position: absolute;
  background-color: white; /* Set default background color for the menu */
  color: black; /* Set default text color for the menu */
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.szh-menu {
  z-index: 20001 !important;
}

.szh-menu--state-opening {
  opacity: 0;
}

.szh-menu--state-open {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  z-index: 9999 !important;
}

.szh-menu__item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.1s ease, color 0.1s ease; /* Smooth transition for hover effect */
  z-index: 9999 !important;
}

.szh-menu__item:hover {
  background-color: rgb(19, 19, 19);
  color: white;
  z-index: 9999 !important;
}

.select-deck-screen {
  background-color: rgb(22, 22, 22);
}

.body-deck-selection {
  background-color: rgb(22, 22, 22);
}

.modal-header .btn-close {
  background-color: white !important;
}

/* Deck Selection Styles */
.deck-selection {
  background-color: rgb(22, 22, 22);
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Four columns */
  align-items: center;
  justify-items: center; /* Center all grid items */
  padding: 20px;
}

.deck-selection h2 {
  color: white;
  margin-bottom: 20px;
}

.deck-item {
  background-color: #333;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: white;
}

.deck-item h3 {
  color: white;
  margin-bottom: 10px;
}

.deck-item button {
  background-color: #dbcbd8;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.deck-item button:hover {
  background-color: #885e77;
  color: white;
}

.hp-control {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-adjust {
  display: flex;
  align-items: center;
}

.hp-btn {
  background-color: #dbcbd8;
  color: black;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.1s ease;
  padding: 0; /* Remove any default padding */
  line-height: 1; /* Ensure the content is centered vertically */
}

.hp-btn:hover {
  background-color: #885e77;
  color: white;
}

.hp-adjust h4 {
  min-width: 40px;
  text-align: center;
}

.card-preview-overlay {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  pointer-events: none;
  transition: left 0.2s ease-in-out;
}

.card-preview-overlay.left-side {
  left: 5%;
  transform: translateY(-50%);
}

.card-preview-overlay.right-side {
  right: 5%;
  transform: translateY(-50%);
}

.card-preview-overlay.center {
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Keep the rest of your preview styles the same */
.card-preview-image {
  width: 400px;
  height: auto;
  border-radius: 4.75% / 3.5%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-in-out;
}

.controls,
.active-player-zones,
.your-zones {
  overflow: hidden;
}

/* Counter Modal Styles */
.counter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.counter-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  color: white;
}

.counter-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: white;
}

.counter-modal-add,
.counter-modal-close {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.1s ease;
  margin-right: 10px;
}

.counter-modal-add:hover,
.counter-modal-close:hover {
  background-color: #885e77;
  color: white;
}

.counter {
  background-color: rgba(126, 126, 126, 0.7); /* Changed from #b4b4b4 to rgba for transparency */
  color: black;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  min-width: 90px;
  padding: 2px;
}

.counter-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.counter-text {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.counter-number {
  background-color: white;
  color: black;
  border-radius: 20%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-buttons {
  display: flex;
  flex-direction: column;
}

.counter-buttons button {
  background-color: #333;
  color: white;
  border: none;
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
}

.counter-buttons button:hover {
  background-color: #885e77 !important;
  cursor: pointer !important;
}

/* Game Log */
.game-log {
  flex: 1;
  overflow: hidden;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  border-top: 2px solid #ccc;
  max-height: 50%;
  float: bottom;
}

.log-title {
  color: rgb(255, 255, 255);
  align-self: center;
  white-space: nowrap;
}

.log-messages {
  flex: 1;
  overflow-y: auto;
  padding: 0 10px;
  background-color: #141414;
  padding-top: 5px;
  width: 100%;
}

.log-message {
  margin-bottom: 7px;
  font-size: 14px;
  background-color: #303030;
  padding: 5px 10px; /* Add some padding for better readability */
  text-align: center; /* Center the text within each message */
  border-radius: 4px; /* Optional: adds rounded corners */
}

.player-info.active-player {
  border: 2px solid rgb(255, 140, 188) !important;
}

.viewed-player {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

.left-column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.game-log {
  flex: 1; /* Take up available space */
  overflow-y: auto;
  margin-top: 10px;
}

/* Media Queries for Smaller Screens */
@media (max-height: 800px) {
  .controls {
    padding-top: 5px;
  }

  .control-btn,
  .counters button,
  .misc-buttons button,
  .kick-button,
  .counters-button {
    padding: 6px;
    font-size: 10px;
  }

  .player-name {
    font-size: 20px;
  }

  .hand-area {
    height: 80px;
  }

  .hand-card {
    width: 70px;
    height: 98px;
    margin: 0 2px;
    transform: translateY(25px);
  }

  .hand-card:hover {
    transform: translateY(-15px) scale(1.5);
  }

  .hand-nav-button {
    width: 25px;
    height: 50px;
    font-size: 18px;
  }
}

@media (max-height: 700px) {
  .controls {
    padding-top: 0;
  }

  .control-btn,
  .counters button,
  .misc-buttons button,
  .kick-button,
  .counters-button {
    padding: 6px;
    font-size: 9px;
  }

  .player-name {
    font-size: 20px;
  }

  .hand-area {
    height: 70px;
  }

  .hand-card {
    width: 60px;
    height: 84px;
    margin: 0 1px;
    transform: translateY(20px);
  }

  .hand-card:hover {
    transform: translateY(-10px) scale(1.5);
  }

  .hand-nav-button {
    width: 20px;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  /* Adjustments for smaller widths */
  .left-column,
  .right-column {
    width: 20%; /* Increase width to accommodate content */
  }

  .middle-column {
    width: 60%;
  }

  .card {
    width: 60px;
    margin: 2px;
  }

  .zone-cards {
    height: 90px;
  }

  .hand-card {
    width: 60px;
    height: 84px;
    margin: 0 1px;
    transform: translateY(20px);
  }

  .hand-card:hover {
    transform: translateY(-10px) scale(1.5);
  }

  .hand-area {
    height: 70px;
  }
}

@media (max-width: 1300px) {
  .other-players{
    overflow-y: auto;
  }

  .player-info {
    width: 65%;
    height: 100%;
  }

  .mulligan-screen .hand-cards img {
    width: 120px;
  }
}

@media (max-width: 1300px) {
  .card-preview-image {
    width: 300px;
  }
}

@media (max-width: 992px) {
  .card-preview-image {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .card-preview-image {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .card-preview-image {
    width: 150px;
  }
}

/* Responsive Adjustments for Cards */

/* For screens 1300px and below */
@media (max-width: 1300px) {
  .control-btn {
    font-size: 10px;
  }

  .card {
    width: 70px;
    margin: 4px;
  }

  .hand-card {
    width: 90px;
    height: 130px;
    margin: 0 3px;
    transform: translateY(60px);
  }

  .hand-card:hover {
    transform: translateY(-40px) scale(1.5);
  }

  .hand-area {
    height: 90px;
  }

  .zone-cards {
    height: 100px;
  }
}

/* For screens 992px and below */
@media (max-width: 992px) {
  .card {
    width: 60px;
    margin: 3px;
  }

  .hand-card {
    width: 80px;
    height: 112px;
    margin: 0 2px;
    transform: translateY(50px);
  }

  .hand-card:hover {
    transform: translateY(-30px) scale(1.5);
  }

  .hand-area {
    height: 80px;
  }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  .card {
    width: 47px;
    margin: 2px;
  }

  .hand-card {
    width: 47px;
    height: 84px;
    margin: 0 1px;
    transform: translateY(40px);
  }

  .hand-card:hover {
    transform: translateY(-20px) scale(1.5);
  }

  .hand-area {
    height: 70px;
  }

  .zone-cards {
    height: 80px;
  }

  .control-btn {
    font-size: 7px;
  }
}

/* Adjust the battlefield-cards and zone-cards containers */
@media (max-width: 992px) {
  .battlefield-cards,
  .zone-cards {
    overflow-y: hidden;
    max-height: 100%;
  }
}

/* Adjustments for card preview images */
@media (max-width: 1300px) {
  .hovered-card-preview img {
    width: 250px;
  }

  .battlefield-cards .card {
    width: 75px;
  }
}

@media (max-width: 992px) {
  .hovered-card-preview img {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .hovered-card-preview img {
    width: 150px;
  }

  .ReactModal_Content {
    transform: translate(-50%,-50%) scale(0.5);
  }
}

.zone-menu-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.zone-action-btn {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.1s ease;
}

.zone-action-btn:hover {
  background-color: #885e77;
  color: white;
}

/* Add horizontal scrollbar styling */
.other-players::-webkit-scrollbar {
  width: 8px; /* Vertical scrollbar width */
  height: auto;
}

.counter-buttons .delete-btn {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #333;
  width: 20px;
  height: 20px;
}

.counter-buttons .delete-btn:hover{
  background-color: #885e77;
  cursor: pointer !important;
}

.Info-modal {
  max-height: 500px;
  overflow: auto;
}

/* Revealed Hand and Info Modal Styles */
.Info-modal-overlay, .revealed-hand-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.Info-modal, .revealed-hand-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  color: white;
}

.Info-modal h2, .revealed-hand-modal h2 {
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Add these if you plan to have buttons in the Info modal */
.Info-modal button, .revealed-hand-modal button, .emote-modal-close {
  background-color: #dbcbd8;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.1s ease;
}

.Info-modal button:hover, .revealed-hand-modal button:hover {
  background-color: #885e77;
  color: white;
}

@media (max-height: 450px) {
  .zone-row {
    min-height: 50%;
  }

  .game-log h5 {
    display: none;
  }

  .active-player-zones, .controls, .zone, .your-zones {
    overflow: auto !important;
  }

  .hand-card {
    height: 50%;
    transform: translateY(30px);
  }

  .other-players {
    overflow-y: auto;
  }

  .your-battlefield .card {
    width: 40px;
  }

  .player-info {
    width: auto;
    height: auto;
  }
}


