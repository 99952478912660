/* src/styles/Home.css */

@font-face {
    font-family: 'Antonio';
    src: url('../fonts/Antonio.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

/* Global Styles */
body {
    font-family: Antonio, sans-serif !important;
    margin: 0;
    padding: 0;
  }
  
/* Sidebar Styles */
.sidebar {
    position: fixed;
    height: 100vh;
    width: 25%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the buttons and logo horizontally */
    justify-content: flex-start; /* Start aligning elements from the top */
  }
  
  /* Logo at the top left */
  .nav-logo {
    width: 50%; /* Adjust size as needed */
    max-width: 150px;
    height: auto; /* Keep the aspect ratio */
    margin-top: 20px; /* Add space at the top */
  }
  

/* Adjust nav-buttons container */
.nav-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px; /* Add spacing between the logo and buttons */
  }
  
  /* Button Styles */
  .btn.nav-button {
    background-color: #DBCBD8;
    color: black;
    border-radius: 15px;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    font-size: 300%;
    display: block;
    padding: 50px;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    transition: color 0.3s ease;
  }
  
  /* Hover effect for nav buttons */
  .btn.nav-button:hover {
    background-color: #644056;
    color: white;
  }

/* Join Button */
.join-button {
    background-color: #DBCBD8 !important;
    color: black !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    border: none !important;
    margin-left: 10px;
    text-align: center;
    transition: background-color 0.1s ease; /* Smooth transition for hover effect */
}

/* Hover effect for Join buttons */
.join-button:hover {
    color: black;
    background-color: #c080b4 !important;
}
  
  /* Button Variations */
  /* .btn.button-newRoom,
  .btn.button-decks {
    flex-basis: 35%;
    padding-top: 15%;
    padding-bottom: 15%;
  }
  
  .btn.button-login {
    flex-basis: 15%;
    padding-top: 10%;
    padding-bottom: 10%;
  } */
  
  /* Main Content Area */
  .main-content {
    margin-left: 25%;
    padding: 20px;
    background-color: rgb(34, 34, 34);
    color: white;
    min-height: 100vh; /* Ensure the content covers the entire viewport height */
    box-sizing: border-box; /* Include padding in the total height calculation */
  }
  
  .main-content h1, .main-content h4 {
    text-align: center;
  }

  .title-container {
    margin-bottom: 3%;
    margin-top: 1.5%;
  }
  
 /* Rooms List */
.rooms-list {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center room items horizontally */
    gap: 10px;
    background-color: rgb(34, 34, 34); /* Ensure the rooms-list background matches */
    width: 100%; /* Ensure full width to center content properly */
  }
  
  /* Room Item */
  .room-item {
    border: 2px solid white;
    color: white !important;
    background-color: black;
    border-radius: 2px;
    padding: 10px;
    width: 95%; /* Adjust this value as needed to control the width of the room items */
    margin: 0 auto; /* Ensure items are centered within the parent */
  }  
  
  /* Room Details */
  .room-details {
    display: flex;
    align-items: center;
  }
  
  /* Individual Sections */
  .room-host,
  .room-occupants,
  .room-name,
  .room-privacy {
    padding: 0 10px;
    border-right: 1px solid white;
  }
  
  .room-host,
  .room-occupants,
  .room-privacy {
    flex: 1;
    text-align: center;
    font-size: 1.2em;
  }
  
  .room-name {
    flex: 3;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
  
  /* Remove border from last text element */
  .room-privacy {
    border-right: none;
  }

  .site-logo {
    width: 100%; /* Adjust this value to fit your layout */
    max-width: 150px; /* Limit the max size */
    height: auto; /* Keep the aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image */
  }

/* Ensure Modal Background is Black and Text is White */
.modal-content {
    background-color: black !important; /* Override Bootstrap modal background */
    color: white !important; /* Ensure text is white */
    width: auto !important;
    min-width: 800px;
  }
  
  .modal-header, .modal-footer {
    border: none !important; /* Remove header and footer borders */
    background-color: black !important; /* Ensure background is black */
  }
  
  .modal-title {
    color: white !important; /* Override title color */
  }
  
  /* Form Control (Input and Textareas) */
  .modal-body .form-control {
    background-color: #333 !important; /* Dark background for input fields */
    color: white !important; /* White text */
    border: 1px solid #555 !important; /* Gray border */
  }
  
  .modal-body .form-control::placeholder {
    color: #bbb !important; /* Placeholder text color */
  }
  
  /* Button Styles */
  .modal-footer .btn {
    background-color: #DBCBD8 !important; /* Button background */
    color: black !important; /* Button text */
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .modal-footer .btn:hover {
    background-color: #E7D8E1 !important; /* Lighter hover color */
    color: black !important;
  }
  
  
  .form-control {
    background-color: #333; /* Dark background for input fields */
    color: white; /* White text */
    border: 1px solid #555; /* Gray border */
  }
  
  .form-control::placeholder {
    color: #bbb; /* Placeholder text color */
  }
  
  .modal-content .new-room-modal {
    width: 100% !important;
  }

  .new-room-modal {
    width: 100% !important;
  }

  /* Deck Modal Styling */
.modal-body .deck-item {
    background-color: #222 !important; /* Dark background for deck item */
    padding: 15px !important;
    border-radius: 5px !important;
    width: auto !important;
  }
  
  .modal-body .deck-details {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  
  .modal-body .deck-list ul {
    padding-left: 20px !important;
  }
  
  .modal-body .deck-list li {
    color: white !important; /* Ensure deck list text is white */
  }
  
  .modal-body .deck-item h5, .modal-body .deck-list h6 {
    color: #DBCBD8 !important; /* Match button color for titles */
  }
  
  /* Button in Modals */
  .modal-footer .btn, .modal-body .btn {
    background-color: #DBCBD8 !important;
    color: black !important;
    border: none !important;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .modal-footer .btn:hover, .modal-body .btn:hover {
    background-color: #885e77 !important;
    color: black !important;
  }

  /* Align the Close and Add New Deck buttons in the footer */
.modal-footer {
    display: flex;
    justify-content: space-between; /* Space out the Close and Add New Deck buttons */
    align-items: center; /* Ensure vertical alignment */
    width: 100%; /* Ensure the buttons take up the full width */
}

.modal-footer .close-btn {
    background-color: #885e77 !important;
    color: white !important;
}

.modal-footer .add-new-deck-btn {
    background-color: #EBE9E9 !important;
}
  
  /* Make the Edit and Delete buttons closer */
  .deck-details .btn {
    margin-right: 5px; /* Adds a small gap between Edit and Delete */
  }
  
  /* .modal-body .button-group {
    display: flex;
    gap: 10px;
  } */
  
  .modal-body .btn {
    padding: 5px 15px;
    margin-right: 0; /* Remove the margin-right */
    font-size: 0.9rem; /* Adjust the size as needed */
  }

  .modal-dialog-centered {
    align-items: center !important;
  }

  .deck-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns */
    gap: 1rem; /* Spacing between grid items */
  }
  
  .deck-item {
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .deck-item:hover {
    transform: scale(1.05);
  }
  
  .deck-details {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .button-group {
    display: flex;
    gap: 0.5rem;
  }

/* Responsive Adjustments for Nav Buttons */

@media (max-width: 1300px) {
  .nav-button {
    font-size: 175% !important;
    padding: 25px !important;
  }

  .site-logo {
    max-width: 100px;
  }
}

/* For screens 992px and below */
@media (max-width: 992px) {
  .btn.nav-button {
    font-size: 150%;
    padding: 30px;
    margin-top: 5%;
  }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  .btn.nav-button {
    font-size: 150%;
    padding: 20px;
    margin-top: 5%;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .main-content {
    margin-left: 0;
  }

  .modal-content {
    min-width: 500px;
  }

  .deck-list {
    grid-template-columns: repeat(2, 1fr); /* Four columns */
  }
}

/* For screens 576px and below */
@media (max-width: 576px) {
  .btn.nav-button {
    font-size: 80%;
    padding: 15px;
    margin-top: 5%;
  }

  .nav-logo {
    width: 70%;
    max-width: none;
  }

  .modal-dialog {
    margin-left: 5% !important;
  }
}

.btn.delete-button {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  background-color: #CD8987;
  color: black;
  border: none;
  border-radius: 5px;
}

.btn.delete-button:hover {
  background-color: #ff4d4d;
  color: black;
}
